
import {useRouter} from "vue-router";
import {ref} from "vue";
import {v4 as uuid} from 'uuid';
import useAuth from "@/modules/auth/composables/useAuth";
import toastr from "toastr"

export default {
    setup() {
        const {push} = useRouter();
        const {createUser} = useAuth()
        const registerForm = ref({
            companyId: uuid(),
            companyName: '',
            companyPhone: '',
            companyAddress: '',
            companyStatus: 'active',
            id: uuid(),
            name: "",
            username: "",
            password: "",
            passwordConfirmation: ""
        })
        const sending = ref(false)

        return {
            registerForm,
            sending,
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await createUser(registerForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await push({name: 'home'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
